import { Component } from '@angular/core';
import { AppointmentService } from '../../../../services/appointment.service';
import { ToastrService } from 'ngx-toastr';
import { SortingType } from '../sort.enum';
import { TerminGetAll } from '../../../../models/termin.model';
import { PaginationComponent } from '../../pagination/pagination.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TerminStatus } from '../../../../models/enums/terminStatus.enum';
import { AuthService } from '../../../../services/auth.service';
import { VehicleType } from '../../../../models/enums/vehicleType.enum';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';


interface PageData {
  pageSize: number;
  date: string;
  currentPage: number;
  search: string;
  sortType: SortingType;
}

@Component({
  selector: 'app-termini-pregled',
  standalone: true,
  imports: [CommonModule, PaginationComponent, FormsModule,NgbDatepickerModule],
  templateUrl: './termini-pregled.component.html',
  styleUrl: './termini-pregled.component.scss'
})
export class TerminiPregledComponent {
  public data: TerminGetAll[] = [];
  pageData: PageData = {
    pageSize: 28,
    date: '',
    currentPage: 1,
    search: '',
    sortType: SortingType.Starije,
  };
  TerminStatus = TerminStatus;
  VehicleType = VehicleType;

  constructor(private authService: AuthService, private appointmentService: AppointmentService, private toastr: ToastrService) {

  }
  onDateChange(selectedDate: any) {
    this.pageData.date = `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`;

    this.appointmentService.getAll(this.pageData).subscribe((response: any) => {
      if (response.status == 200) {
        this.data = response.data.data;
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }

  isAdmin() {
    return this.authService.isRole('Admin')
  }


  approveAppointment(status: TerminStatus, id: number) {
    this.appointmentService.approveAppointment({ status: status, id: id }).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message);
        this.onDateChange(null);
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }
}
