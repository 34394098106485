import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { PrivateHeaderComponent } from '../private-header/private-header.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { PublicFooterComponent } from '../../public/layout/public-footer/public-footer.component';

@Component({
  selector: 'app-private-layout',
  standalone: true,
  imports: [RouterModule,PublicFooterComponent,PrivateHeaderComponent,LoadingBarHttpClientModule,NgxUiLoaderModule],
  templateUrl: './private-layout.component.html',
  styleUrl: './private-layout.component.scss'
})
export class PrivateLayoutComponent {
  constructor(private router : Router)
  {
    
  }
  navigate(route: string) {
    this.router.navigate([route]).then(() => {
      window.scrollTo(0, 0);
    });
  }
}
