import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { inject } from '@angular/core'; // Za injektovanje u interseptoru
import { finalize } from "rxjs";

export const AuthInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const ngxService = inject(NgxUiLoaderService); // Injektovanje servisa unutar funkcionalnog interseptora

    const idToken = localStorage.getItem('token');

    ngxService.start(); // Pokrećemo loader pre nego što se HTTP zahtev pošalje

    // Dodavanje Authorization header-a ako je token prisutan
    if (idToken) {
        req = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + idToken)
        });
    }

    return next(req).pipe(
        // Zaustavljamo loader kada se odgovor vrati
        finalize(() => {
            ngxService.stop(); // Zaustavljamo loader nakon što odgovor stigne
        })
    );
};
