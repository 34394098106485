import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { RouterModule, provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { AppConfig } from './config/config';
import { provideToastr } from 'ngx-toastr';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { StandardErrorInterceptor } from './interceptors/standard-error-interceptor';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderRouterModule, POSITION, SPINNER } from 'ngx-ui-loader';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#0B0078",
  fgsColor: "#0B0078",
  bgsPosition: POSITION.centerCenter,
  overlayColor: 'rgba(255,255,255,0.85)', 
  bgsOpacity: 0.85,
  bgsSize: 70,
  fgsSize: 70,
  fgsType: SPINNER.foldingCube,
  bgsType: SPINNER.foldingCube,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withFetch(), withInterceptors([
      AuthInterceptor,
      StandardErrorInterceptor,
    ])),
    importProvidersFrom(
      NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),NgxUiLoaderHttpModule.forRoot({showForeground:true})
    ),
    AppConfig,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule,
    BrowserModule,
    provideToastr(),
    provideAnimations(),
  ]
};
