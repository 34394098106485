import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

export interface ResultData {
  komunalna: number; // Municipal fee
  ratZahtevNalepnica: number; // Installment request for the sticker
  ratNalepnica: number; // Installment for the sticker
  ratSaobracajna: number; // Installment for the traffic insurance
  nbsNalepnica: number; // National Bank sticker cost
  nbsSaobracajna: number; // National Bank traffic insurance cost
  ratZahtevSaobracajna: number; // Installment request for traffic insurance
  nbsTablice: number; // National Bank license plate cost
  ratTablice: number; // Installment for license plates
  polisa: number; // Policy cost
  zeleniKarton: number; // Green card cost
  porez: number; // Tax
  elektricnoVozilo: number; // Electric vehicle discount or fee
  premijski: number; // Premium
  tehnicki: number; // Technical inspection cost
  rentCar: number; // Rental car-related cost
  invalid: number; // Disability discount or fee
  pripremaDokumentacije: number; // Sticker issuance cost
  odlazakSup: number; // Cost for plates and traffic registration
  sum: number; // Total sum
}

@Component({
  selector: 'app-kalkulator-registracije-motor',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './kalkulator-registracije-motor.component.html',
  styleUrl: './kalkulator-registracije-motor.component.scss'
})

export class KalkulatorRegistracijeMotorComponent implements OnInit {
  data: any = {
    tipVozila: 1,
    elektricnoVozilo: 1,
    godiste: null,
    snaga: null,
    zapremnina: null,
    premijskiStepen: 4,
    tempPremijskiStepen: 4,
    invalid: false,
    saobracajna: false,
    tablice: false,
    zeleniKarton: false,
    brojSteta: 0,
    pripremaDokumentacije: false,
    odlazakSup: false,
    rentCar: false,
    tehnickiPregled: false,
  };
  cena: number = 0;
  modal: boolean = false;
  image: boolean = false;
  imagemap: number = 0;
  rezultat: ResultData = {
    komunalna: 0,
    ratZahtevNalepnica: 400,
    ratNalepnica: 250,
    ratSaobracajna: 0,
    nbsNalepnica: 236,
    nbsSaobracajna: 0,
    ratZahtevSaobracajna: 0,
    nbsTablice: 0,
    ratTablice: 0,
    polisa: 0,
    zeleniKarton: 0,
    porez: 0,
    elektricnoVozilo: 0,
    premijski: 0,
    tehnicki: 0,
    rentCar: 0,
    invalid: 0,
    pripremaDokumentacije: 0,
    odlazakSup: 0,
    sum: 0,
  }
  ngOnInit(): void {

  }
  constructor(private toastr: ToastrService) {

  }
  toggleModal() {
    this.modal = !this.modal;
  }
  toggleModalimage(num: number) {
    this.imagemap = num;
    this.image = !this.image;
  }
  calculate(f: any) {
    if (!f) {
      this.toastr.warning("Molimo popunite ispravno formu.")
      return;
    }

    //komunalna taksa
    if (this.data.zapremnina! >= 0 && this.data.zapremnina! <= 50)
      this.rezultat.komunalna = 0;
    if (this.data.zapremnina! >= 50 && this.data.zapremnina! <= 125)
      this.rezultat.komunalna = 630;
    else if (this.data.zapremnina! >= 125 && this.data.zapremnina! <= 250)
      this.rezultat.komunalna = 930;
    else if (this.data.zapremnina! >= 250 && this.data.zapremnina! <= 500)
      this.rezultat.komunalna = 1550;
    else if (this.data.zapremnina! >= 500 && this.data.zapremnina! <= 1200)
      this.rezultat.komunalna = 1910;
    else if (this.data.zapremnina! >= 1200)
      this.rezultat.komunalna = 2350;


    //polisa
    if (this.data.zapremnina! <= 50)
      this.rezultat.polisa = 1714;
    else if (this.data.zapremnina! > 50 && this.data.zapremnina! <= 100)
      this.rezultat.polisa = 2842;
    else if (this.data.zapremnina! > 100 && this.data.zapremnina! <= 175)
      this.rezultat.polisa = 4269;
    else if (this.data.zapremnina! > 175 && this.data.zapremnina! <= 250)
      this.rezultat.polisa = 5984;
    else if (this.data.zapremnina! > 250 && this.data.zapremnina! <= 500)
      this.rezultat.polisa = 9697;
    else if (this.data.zapremnina! > 500 && this.data.zapremnina! <= 750)
      this.rezultat.polisa = 15408;
    else if (this.data.zapremnina! > 750)
      this.rezultat.polisa = 21120;

    //broj steta
    if (this.data.brojSteta == 0) //bolji premijski stepen za 1
    {
      this.data.tempPremijskiStepen = this.data.premijskiStepen - 1;
      if (this.data.tempPremijskiStepen < 1)
        this.data.tempPremijskiStepen = 1;
    }
    else {
      this.data.tempPremijskiStepen = this.data.premijskiStepen + 3;
      if (this.data.tempPremijskiStepen > 12)
        this.data.tempPremijskiStepen = 12;
    }
    //premijski
    switch (this.data.tempPremijskiStepen) {
      case 1: {
        this.rezultat.premijski = -Math.round(this.rezultat.polisa * 0.25)
        break;
      }
      case 2: {
        this.rezultat.premijski = -Math.round(this.rezultat.polisa * 0.15)
        break;
      }
      case 3: {
        this.rezultat.premijski = -Math.round(this.rezultat.polisa * 0.05)
        break;
      }
      case 4: {
        this.rezultat.premijski = -Math.round(this.rezultat.polisa * 0.0)
        break;
      }
      case 5: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 0.15)
        break;
      }
      case 6: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 0.30)
        break;
      }
      case 7: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 0.50)
        break;
      }
      case 8: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 0.70)
        break;
      }
      case 9: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 0.90)
        break;
      }
      case 10: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 1.10)
        break;
      }
      case 11: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 1.30)
        break;
      }
      case 12: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 1.50)
        break;
      }
      default: {
        this.rezultat.premijski = 0;
        break;
      }
    }



    let starost = new Date().getFullYear() - this.data.godiste!;
    //porez
    if (this.data.zapremnina! <= 50) {
      this.rezultat.porez = 0;
    }
    else if (this.data.zapremnina! >= 50 && this.data.zapremnina! <= 125) {
      if (starost <= 5)
        this.rezultat.porez = 2080;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 1768;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 1560;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 1248;
      if (starost >= 20)
        this.rezultat.porez = 416;
    }
    else if (this.data.zapremnina! >= 126 && this.data.zapremnina! <= 250) {
      if (starost <= 5)
        this.rezultat.porez = 3490;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 2966;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 2617;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 2094;
      if (starost >= 20)
        this.rezultat.porez = 698;
    }
    else if (this.data.zapremnina! >= 251 && this.data.zapremnina! <= 500) {
      if (starost <= 5)
        this.rezultat.porez = 5250;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 4462;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 3937;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 3150;
      if (starost >= 20)
        this.rezultat.porez = 1050;
    }
    else if (this.data.zapremnina! >= 501 && this.data.zapremnina! <= 750) {
      if (starost <= 5)
        this.rezultat.porez = 10510;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 8933;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 7882;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 6306;
      if (starost >= 20)
        this.rezultat.porez = 2102;
    }
    else if (this.data.zapremnina! >= 751 && this.data.zapremnina! <= 1100) {
      if (starost <= 5)
        this.rezultat.porez = 12850;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 10922;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 9637;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 7710;
      if (starost >= 20)
        this.rezultat.porez = 2570;
    }
    else if (this.data.zapremnina! >= 1101) {
      if (starost <= 5)
        this.rezultat.porez = 18380;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 15623;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 13785;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 11028;
      if (starost >= 20)
        this.rezultat.porez = 3676;
    }


    //dodatne informacije o vozilu
    if (this.data.zeleniKarton)
      this.rezultat.zeleniKarton = 2000;
    else
      this.rezultat.zeleniKarton = 0;

    //saobracajna dozvola
    if (this.data.saobracajna) {
      this.rezultat.ratSaobracajna = 820;
      this.rezultat.ratZahtevSaobracajna = 380;
      this.rezultat.nbsSaobracajna = 660;
    }
    else {
      this.rezultat.ratZahtevSaobracajna = 0;
      this.rezultat.ratSaobracajna = 0;
      this.rezultat.nbsSaobracajna = 0;
    }

    //tablice
    if (this.data.tablice) {
      this.rezultat.nbsTablice = 553;
      this.rezultat.ratTablice = 970;
    }
    else {
      this.rezultat.ratTablice = 0;
      this.rezultat.nbsTablice = 0;
    }

    //rent
    if (this.data.rentCar)
      this.rezultat.rentCar = this.rezultat.polisa * -0.4;
    else
      this.rezultat.rentCar = 0;

    //invalid
    if (this.data.invalid)
      this.rezultat.invalid = this.rezultat.polisa * -0.1
    else
      this.rezultat.invalid = 0;


    //USLUGE

    //tehnicki
    if (this.data.tehnickiPregled) {
      this.rezultat.tehnicki = 4000;
    }
    else {
      this.rezultat.tehnicki = 0;
    }
    //tabliceSaobracana
    if (this.data.pripremaDokumentacije) {
      this.rezultat.pripremaDokumentacije = 1500;
    }
    else {
      this.rezultat.pripremaDokumentacije = 0;
    }
    //izdavanjeNalepnice
    if (this.data.odlazakSup) {
      this.rezultat.odlazakSup = 2000;
    }
    else {
      this.rezultat.odlazakSup = 0;
    }

    //stavke
    this.rezultat.sum = this.rezultat.komunalna + this.rezultat.polisa + this.rezultat.premijski + this.rezultat.porez;
    this.rezultat.sum += this.rezultat.zeleniKarton;
    this.rezultat.sum += (this.rezultat.ratSaobracajna + this.rezultat.ratZahtevSaobracajna + this.rezultat.nbsSaobracajna);
    this.rezultat.sum += (this.rezultat.ratTablice + this.rezultat.nbsTablice);
    this.rezultat.sum += (this.rezultat.invalid + this.data.rentCar);
    this.rezultat.sum += (this.rezultat.ratNalepnica + this.rezultat.ratZahtevNalepnica+this.rezultat.nbsNalepnica);
    //usluge
    this.rezultat.sum += (this.rezultat.tehnicki);
    this.rezultat.sum += (this.rezultat.odlazakSup + this.rezultat.pripremaDokumentacije);

    this.toggleModal();
  }

}
