import { Component, OnInit, ViewChild } from '@angular/core';
import { PaginationComponent } from '../pagination/pagination.component';
import { CommonModule } from '@angular/common';
import { AppointmentService } from '../../../services/appointment.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { TerminGetAll } from '../../../models/termin.model';
import { TerminStatus } from '../../../models/enums/terminStatus.enum';
import { VehicleType } from '../../../models/enums/vehicleType.enum';
import { FormsModule } from '@angular/forms';
import { SortingType } from './sort.enum';

interface PageData {
  pageSize: number;
  date: string | null;
  currentPage: number;
  search: string;
  sortType: SortingType;
}


@Component({
  selector: 'app-termini',
  standalone: true,
  imports: [CommonModule, PaginationComponent,FormsModule],
  templateUrl: './termini.component.html',
  styleUrl: './termini.component.scss'
})

export class TerminiComponent implements OnInit {
  pageData: PageData = {
    pageSize: 5,
    date: null,
    currentPage: 1,
    search: '',
    sortType: SortingType.Podrazumevano,
  };
  
  types = SortingType;
  VehicleType = VehicleType;
  TerminStatus = TerminStatus;
  public data : TerminGetAll[] = [];
  @ViewChild(PaginationComponent) paginationComponentRef!: PaginationComponent;
  count: number = 0;

  constructor(private appointmentService: AppointmentService, private toastr: ToastrService, private authService: AuthService) {

  }
  isAdmin(){
    return this.authService.isRole('Admin')
  }
  ngOnInit(): void {
    this.reloadTable();
  }

  setDate(event:any){
    const inputElement = event.target as HTMLInputElement;
    const selectedDate = inputElement.value;
    if(selectedDate == '')
      this.pageData.date = null
    else
      this.pageData.date = selectedDate;
    this.reloadTable();
  }

  reloadTable() {
    this.appointmentService.getAll(this.pageData).subscribe((response: any) => {
      if (response.status == 200) {
        this.data = response.data.data;
        this.count = response.data.count;
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }
  
  pageChange(value: number) {
    this.pageData.currentPage = value
    this.reloadTable()
  }

  approveAppointment(status : TerminStatus, id : number){
    this.appointmentService.approveAppointment({status: status, id : id}).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message);
        this.reloadTable();
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }

  
}