<div class="login">
    <form (submit)="f.valid" class="form" #f="ngForm">
        <div class="header-login">
            <span class="title">REGISTRACIJA NA SISTEM</span>
            <span class="content">
                Registruj se na naš sistem, upravljajte svojim vozilima i pratite istoriju održavanja i registracije.
                Pozovite prijatelje i ostvarite popuste u Auto Centru Čuburac.
            </span>
        </div>
        <div class="row">
            <div class="element">
                <label class="labelPopup">IME</label>
                <input class="form-control" name="ime" [(ngModel)]="user.ime" #ime="ngModel" required
                    autocomplete="off" title="Ime" />
                @if(f.submitted && ime.invalid){
                <div class="form-control-error">Ime je obavezno polje</div>
                }
            </div>
            <div class="element">
                <label class="labelPopup">PREZIME</label>
                <input class="form-control" name="prezime" [(ngModel)]="user.prezime" #prezime="ngModel" required
                    autocomplete="off" title="Prezime" />
                @if(f.submitted && prezime.invalid){
                <div class="form-control-error">Prezime je obavezno polje</div>
                }
            </div>
        </div>
        <br>
        <div class="row">
            <div class="element">
                <label class="labelPopup">ADRESA</label>
                <input class="form-control" name="adresa" [(ngModel)]="user.adresa" #adresa="ngModel" required
                    autocomplete="off" />
                @if(f.submitted && adresa.invalid){
                <div class="form-control-error">Adresa je obavezno polje</div>
                }
            </div>
            <div class="element">
                <label class="labelPopup">TELEFON</label>
                <input class="form-control" name="telefon" [(ngModel)]="user.telefon" #telefon="ngModel" required
                    autocomplete="off" pattern="^\d{7,15}$"/>
                @if(f.submitted && telefon.invalid){
                <div class="form-control-error">Telefon je obavezno polje</div>
                }
            </div>
        </div>
        <br>
        <div class="row">
            <div class="element">
                <label class="labelPopup">EMAIL</label>
                <input class="form-control" name="email" [(ngModel)]="user.email" #email="ngModel" required
                    autocomplete="off" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" title="Invalid email address" />
                @if(f.submitted && email.invalid){
                <div class="form-control-error">Email je obavezno polje</div>
                }
            </div>
            <div class="element">
                <label class="labelPopup">LOZINKA</label>
                <div class="password-wrapper">
                    <input [type]="passwordFieldType" class="form-control" name="password" [(ngModel)]="user.password"
                        #password="ngModel" required autocomplete="off" />
                    <span class="toggle-password" (click)="togglePasswordVisibility()">
                        <img [src]="passwordFieldType === 'password' ? '../../../../assets/icons/passwordshow.svg' : '../../../../assets/icons/passwordhide.svg'"
                            alt="Toggle Password" />
                    </span>
                </div>
                @if(f.submitted && password.invalid){
                <div class="form-control-error">Lozinka je obavezno polje</div>
                }
            </div>
        </div>
        <br>
        <button type="submit" class="button-sucess" [disabled]="!f.valid" (click)="register()">
            <img class="only-icon" src="../../../../assets/icons/login.svg" />REGISTRUJ SE
        </button>
        <br><br>
        <span class="register">Vec imate nalog? Jednostavno se prijavite na stranici <span class="click"
                (click)="navigate('login')">prijave</span>!</span>
    </form>
</div>