<div class="banner">
    <span class="title">Cena registracije vozila</span>
    <span class="content">Registracijom vozila u našem auto centru, završavate sve na jednom mestu i u jednom danu. Registrujte Vaše putničko vozilo, teretno vozilo do 3.5t ili motor.</span>
</div>
<div class="zakazivanje">
    <div class="zakazivanje-body">
        <div class="car-selector">
            <div class="row"><span class="title">Izaberite tip vozila</span></div>
            <div class="row">
                <div class="radio-button" (click)="navigate('/kalkulator-registracije/putnicka')">
                    <img src="../../../../../assets/icons/putnickoptype.svg" />
                    Putničko vozilo
                </div>
                <div class="radio-button" (click)="navigate('/kalkulator-registracije/motorna')">
                    <img src="../../../../../assets/icons/motorbiketype.svg" />
                    Motocikl / Moped
                </div>
                <div class="radio-button" (click)="navigate('/kalkulator-registracije/teretna')">
                    <img src="../../../../../assets/icons/truck.svg" />
                    Teretno vozilo do 3.5t
                </div>
            </div>
            
        </div>
    </div>
</div>