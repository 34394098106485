<div class="row">
    <div class="calendar">
        <ngb-datepicker
        #dp
        name="datum"
        (dateSelect)="onDateChange($event)"
        required
      ></ngb-datepicker>
      
    </div>

    <div class="termini">
        <table>
            <tr *ngFor="let item of data">
                <td>Datum: <b>{{ item.time | date:'dd.MM.yyyy' }}</b><br>Vreme: <b>{{ item.time | date:'HH:mm' }}</b></td>
                <td>@if(item.tipVozila == VehicleType.Putnicko_vozilo){ <img class="icon"
                        src="../../../../assets/icons/putnickoptype.svg">} @else{ 
                            @if(item.tipVozila == VehicleType.Motor){
                                <img class="icon"
                        src="../../../../assets/icons/motorbiketype.svg">
                            }
                            @else{
                                <img class="icon"
                        src="../../../../assets/icons/truck.svg">
                            }

                        } </td>
                <td>{{ item.ime }} {{ item.prezime }}</td>
                <td>{{ item.vozilo }}</td>
                <td>{{ item.telefon }} <br> {{ item.email }}</td>
                <td><span [ngClass]="{
                  'cekanje': item.status == 1,
                  'odbijen': item.status == 2,
                  'odobren': item.status == 3,
                  'zavrsen': item.status == 4,
                  'nijesepojavio': item.status == 5,
              }">{{ TerminStatus[item.status].toString().replaceAll('_',' ') }}</span></td>
    
                <td class="actions" *ngIf="isAdmin() && item.status == TerminStatus.Na_cekanju">
                    <img class="icons" title="Potvrdi vozilo"
                        (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Odobren, item.id)"
                        src="../../../../assets/icons/approve.svg" />Odobri
                    <img class="icons" title="Odbij vozilo"
                        (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Odbijen, item.id)"
                        src="../../../../assets/icons/reject.svg" />Odbij
    
                </td>
                <td class="actions" *ngIf="isAdmin() && item.status == TerminStatus.Odobren">
                    <img class="icons" title="Pojavio se"
                        (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Zavrsen, item.id)"
                        src="../../../../assets/icons/approve.svg" />Pojavio se
                    <img class="icons" title="Nije se pojavio"
                        (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Nije_se_pojavio, item.id)"
                        src="../../../../assets/icons/reject.svg" />Nije se pojavio
                </td>
                <td class="actions"
                    *ngIf="isAdmin() && (item.status == TerminStatus.Odobren || item.status == TerminStatus.Na_cekanju)">
    
                </td>
            </tr>
        </table>
    </div>
</div>