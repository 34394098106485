<div class="banner">
    <span class="title">Online zakazivanje</span>
    <span class="content">Zakazivanje tehničkog pregleda za putnička i motorna vozila nikada nije bilo jednostavnije. Nakon što unesete sve potrebne podatke i zakažete termin, na vašu email adresu stići će obaveštenje o podnetom zahtevu. U slučaju da vaš termin bude prihvaćen, dobićete potvrdu sa svim detaljima. Ako termin ne bude odobren, stići će vam informacija o odbijenom zahtevu, uz mogućnost da izaberete neki drugi termin.<br><br>
        Dodatno, dan pre zakazanog termina dobićete podsetnik kako biste bili sigurni da ne zaboravite na pregled.</span>
</div>
<div class="zakazivanje">
    <div class="zakazivanje-body">
        @if(step == 0)
        {
        <div class="car-selector">
            <div class="row"><span class="title">Izaberite tip vozila</span></div>
            <div class="row">
                <div class="radio-button" [ngClass]="{ 'selected': data.tipVozila == 1 }" (click)="data.tipVozila = 1">
                    <img src="../../../../../assets/icons/putnickoptype.svg" />
                    Putničko vozilo
                </div>
                <div class="radio-button" [ngClass]="{ 'selected': data.tipVozila == 2 }" (click)="data.tipVozila = 2">
                    <img src="../../../../../assets/icons/motorbiketype.svg" />
                    Motocikl
                </div>
                <div class="radio-button" [ngClass]="{ 'selected': data.tipVozila == 3 }" (click)="data.tipVozila = 3">
                    <img src="../../../../../assets/icons/truck.svg" />
                    Teretno vozilo do 3.5t
                </div>
            </div>
            @if(isLoggedInUser() && data.tipVozila != 0)
            {
                <div class="row"><span class="title">Izaberite neko od Vaših vozila</span></div>
                <div class="row">
                    <ng-container *ngFor="let vehicle of myVehicles">
                        <div *ngIf="vehicle.type == data.tipVozila" 
                             class="radio-button" 
                             [ngClass]="{ 'selected': data.VehicleId == vehicle.key }" 
                             (click)="data.VehicleId = vehicle.key; data.vozilo = vehicle.value">
                            {{ vehicle.value }}
                        </div>
                    </ng-container>
                    <div (click)="navigate('panel/vozila')" class="radio-button">
                        <B>DODAJ VOZILO</B>
                    </div>
                </div>
            }
        </div>
        }
        @else if (step==1)
        {
        <div class="car-selector">

            <div class="row"><span class="title">Vaše informacije</span></div>
            <div class="row-2">
                <div class="form-col-50">
                    <label class="labelPopup">Ime</label>
                    <input class="form-control" name="firstName" [(ngModel)]="data.firstName" #firstName="ngModel"
                        autocomplete="off" />
                </div>
                <div class="form-col-50">
                    <label class="labelPopup">Prezime</label>
                    <input class="form-control" name="lastName" [(ngModel)]="data.lastName" #lastName="ngModel"
                        autocomplete="off" />
                </div>

                <div class="form-col-50">
                    <label class="labelPopup">Email</label>
                    <input class="form-control" name="email" [(ngModel)]="data.email" #email="ngModel" [disabled]="isLoggedInUser()"
                        autocomplete="off" />
                </div>
                <div class="form-col-50">
                    <label class="labelPopup">Telefon</label>
                    <input class="form-control" name="Phone" [(ngModel)]="data.phone" #Phone="ngModel"
                        autocomplete="off" />
                </div>
                <div class="form-col-50">
                    <label class="labelPopup">Vozilo</label>
                    <input class="form-control" name="Vozilo" [(ngModel)]="data.vozilo" #Vozilo="ngModel"
                        autocomplete="off" [disabled]="(data.VehicleId != null || data.VehicleId !=0) && isLoggedInUser()"/>
                </div>
            </div>
        </div>
        }
        @else if (step==2)
        {
        <div class="car-selector">
            <div class="row"><span class="title">Izaberite termin</span></div>
            <div class="row">
                <div class="termin-row">
                    <div class="datum-picker">
                        <ngb-datepicker #dp name="datum" [(ngModel)]="data.datum" [minDate]="minDate"
                            [maxDate]="maxDate" (ngModelChange)="onDateChange($event); $event.stopPropagation()"
                            #datum="ngModel" required style="width: 100%;"></ngb-datepicker>
                    </div>
                    <div class="termini">
                        <div class="appointment" *ngFor="let termin of termini" (click)="selectAppointment(termin)"
     [ngClass]="{'selected': data.tempdatum == termin}">
    {{ termin.getHours().toString().padStart(2, '0') }}:{{ termin.getMinutes().toString().padStart(2, '0') }} - 
    {{ calculateEndTime(termin) }}
</div>

                    </div>
                </div>
            </div>
        </div>
        <br>
        }

        <button class="button-sucess" (click)="save()" >
            @if(step
            < 2) { SLEDEĆI KORAK<img src='../../../../assets/icons/next.svg' />
            }
            @else
            {
            ZAKAŽI TERMIN<img src='../../../../assets/icons/submit.svg' />
            }
        </button>
    </div>
</div>