<div class="banner">
    <span class="title">@if(vehicle.id){IZMENI}@else{DODAJ} VOZILO</span>
</div>
<div class="body">
    <form (submit)="f.valid" class="form col-md-12" #f="ngForm">
        @if(selected == 1)
        {
        <div class="row">
            <span class="title">Izaberite tip vozila</span>
            <div class="row-type">
                <div class="radio-button" [ngClass]="{ 'selected': vehicle.vehicleType == 1 }"
                    (click)="vehicle.vehicleType = 1">
                    <img src="../../../../../assets/icons/putnickoptype.svg" />
                    Putničko vozilo
                </div>
                <div class="radio-button" [ngClass]="{ 'selected': vehicle.vehicleType == 2 }"
                    (click)="vehicle.vehicleType = 2">
                    <img src="../../../../../assets/icons/motorbiketype.svg" />
                    Motocikl
                </div>
                <div class="radio-button" [ngClass]="{ 'selected': vehicle.vehicleType == 3 }"
                    (click)="vehicle.vehicleType = 3">
                    <img src="../../../../../assets/icons/truck.svg" />
                    Teretno vozilo do 3.5t
                </div>
            </div>
        </div>
        }
        @else if(selected == 2){
        <div class="row">
            <div class="col50">
                <label class="labelPopup">Brend</label>
                <input class="form-control" name="Brend" [(ngModel)]="vehicle.brend" #Brend="ngModel" required
                    autocomplete="off" maxlength="30" />
                <div *ngIf="f.submitted && Brend.invalid" class="form-control-error">Brend vozila je obavezno
                    polje</div>

            </div>
            <div class="col50">
                <label class="labelPopup">Model vozila</label>
                <input class="form-control" name="Model" [(ngModel)]="vehicle.model" #Model="ngModel" required
                    autocomplete="off" maxlength="30" />
                <div *ngIf="f.submitted && Model.invalid" class="form-control-error">Model vozila je obavezno
                    polje</div>
            </div>
            <div class="col50">
                <label class="labelPopup">Naziv vozila</label>
                <input class="form-control" name="Name" [(ngModel)]="vehicle.name" #Name="ngModel" required
                    autocomplete="off" maxlength="30" />
                <div *ngIf="f.submitted && Name.invalid" class="form-control-error">Ime vozila je obavezno
                    polje</div>
            </div>
            <div class="col50">
                <label class="labelPopup">Registraciona oznaka</label>
                <input class="form-control" name="LicensePlate" [(ngModel)]="vehicle.licensePlate"
                    #LicensePlate="ngModel" required placeholder="BG-1234-AA" autocomplete="off" maxlength="30" />
                <div *ngIf="f.submitted && LicensePlate.invalid" class="form-control-error">Registraciona oznaka je
                    obavezno
                    polje</div>
            </div>
            <div class="col50">
                <label class="labelPopup">VIN</label>
                <input class="form-control" name="vin" [(ngModel)]="vehicle.vin" #vin="ngModel" required
                    autocomplete="off" maxlength="50" />
                <div *ngIf="f.submitted && vin.invalid" class="form-control-error">VIN je obavezno polje</div>
            </div>
            <div class="col50">
                <label class="labelPopup">Datum isteka registracije</label>
                <input class="form-control" type="date" name="registrationExpirationDate"
                    (ngModelChange)="this.vehicle.registrationExpirationDate = $event" [(ngModel)]="this.regDate"
                    #registrationExpirationDate="ngModel" required />
                <div *ngIf="f.submitted && registrationExpirationDate.invalid" class="form-control-error">Datum
                    isteka
                    registracije je obavezno polje</div></div>
                <div class="col33">
                    <label class="labelPopup">Godina proizvodnje</label>
                    <input class="form-control" type="number" min="1" max="2100" name="ProductionYear"
                        [(ngModel)]="vehicle.productionYear" #ProductionYear="ngModel" required autocomplete="off" />
                    <div *ngIf="f.submitted && ProductionYear.invalid" class="form-control-error">Godina proizvodnje je
                        obavezno
                        polje</div>
                </div>
                <div class="col33">
                    <label class="labelPopup">Snaga (KW)</label>
                    <input class="form-control" type="number" max="5000" min="1" name="Power"
                        [(ngModel)]="vehicle.power" #Power="ngModel" required autocomplete="off" />
                    <div *ngIf="f.submitted && Power.invalid" class="form-control-error">Snaga je obavezno
                        polje</div>
                </div>
                <div class="col33">
                    <label class="labelPopup">Zapremnina (cm3)</label>
                    <input class="form-control" max="10000" type="number" min="1" name="EngineDisplacement"
                        [(ngModel)]="vehicle.engineDisplacement" #EngineDisplacement="ngModel" required
                        autocomplete="off" />
                    <div *ngIf="f.submitted && EngineDisplacement.invalid" class="form-control-error">Zapremnina motora
                        je obavezno polje</div>
                </div>
        </div>
        }
        <br>
        <div class="rowbutton">
            <button class="button-sucess button-submit" (click)="$event.stopPropagation(); save()"
                [disabled]="!f.valid"><img src='../../../../assets/icons/submit.svg' />
                @if(this.selected == 2){
                    SAČUVAJ VOZILO
                }
                @else {
                    DALJE POSTAVKE
                }
            </button>
            <button class="button-cancel button" (click)="$event.stopPropagation(); navigate('panel/vozila')"><img
                    src='../../../../assets/icons/close-white.svg' />VRATI NAZAD</button>
        </div>
    </form>
</div>