import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

export interface ResultData {
  komunalna: number; // Municipal fee
  ratZahtevNalepnica: number; // Installment request for the sticker
  ratNalepnica: number; // Installment for the sticker
  ratSaobracajna: number; // Installment for the traffic insurance
  nbsNalepnica: number; // National Bank sticker cost
  nbsSaobracajna: number; // National Bank traffic insurance cost
  ratZahtevSaobracajna: number; // Installment request for traffic insurance
  nbsTablice: number; // National Bank license plate cost
  ratTablice: number; // Installment for license plates
  polisa: number; // Policy cost
  zeleniKarton: number; // Green card cost
  porez: number; // Tax
  elektricnoVozilo: number; // Electric vehicle discount or fee
  premijski: number; // Premium
  tehnicki: number; // Technical inspection cost
  taxi: number; // Taxi-related cost
  rentCar: number; // Rental car-related cost
  invalid: number; // Disability discount or fee
  pripremaDokumentacije: number; // Sticker issuance cost
  odlazakSup: number; // Cost for plates and traffic registration
  sum: number; // Total sum
}

@Component({
  selector: 'app-kalulator-registracije',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './kalulator-registracije.component.html',
  styleUrls: ['./kalulator-registracije.component.scss', './kalulator-registracije-mobile.component.scss']
})

export class KalulatorRegistracijeComponent implements OnInit {
  data: any = {
    tipVozila: 1,
    elektricnoVozilo: 1,
    godiste: null,
    snaga: null,
    zapremnina: null,
    premijskiStepen: 4,
    tempPremijskiStepen: 4,
    invalid: false,
    taxi: false,
    saobracajna: false,
    tablice: false,
    zeleniKarton: false,
    brojSteta: 0,
    pripremaDokumentacije: false,
    odlazakSup: false,
    rentCar: false,
    tehnickiPregled: false,
  };
  modal: boolean = false;
  image: boolean = false;
  imagemap: number = 0;

  rezultat: ResultData = {
    komunalna: 0,
    ratZahtevNalepnica: 400,
    ratNalepnica: 250,
    ratSaobracajna: 0,
    nbsNalepnica: 236,
    nbsSaobracajna: 0,
    ratZahtevSaobracajna: 0,
    nbsTablice: 0,
    ratTablice: 0,
    polisa: 0,
    zeleniKarton: 0,
    porez: 0,
    elektricnoVozilo: 0,
    premijski: 0,
    tehnicki: 0,
    taxi: 0,
    rentCar: 0,
    invalid: 0,
    pripremaDokumentacije: 0,
    odlazakSup: 0,
    sum: 0,
  }
  ngOnInit(): void {

  }
  constructor(private toastr: ToastrService) {

  }
  toggleModal() {
    this.modal = !this.modal;
  }
  toggleModalimage(num: number) {
    this.imagemap = num;
    this.image = !this.image;
  }
  calculate(f: any) {
    if (!f) {
      this.toastr.warning("Molimo popunite ispravno formu.")
      return;
    }

    //komunalna taksa
    if (this.data.zapremnina >= 0 && this.data.zapremnina <= 1150)
      this.rezultat.komunalna = 780;
    else if (this.data.zapremnina >= 1151 && this.data.zapremnina <= 1300)
      this.rezultat.komunalna = 1550;
    else if (this.data.zapremnina >= 1301 && this.data.zapremnina <= 1600)
      this.rezultat.komunalna = 2350;
    else if (this.data.zapremnina >= 1601 && this.data.zapremnina <= 2000)
      this.rezultat.komunalna = 3150;
    else if (this.data.zapremnina >= 2001 && this.data.zapremnina <= 3000)
      this.rezultat.komunalna = 4740;
    else if (this.data.zapremnina >= 3001)
      this.rezultat.komunalna = 7840;


    //polisa
    if (this.data.snaga <= 22)
      this.rezultat.polisa = 9779;
    else if (this.data.snaga > 22 && this.data.snaga <= 33)
      this.rezultat.polisa = 11682;
    else if (this.data.snaga > 33 && this.data.snaga <= 44)
      this.rezultat.polisa = 13600;
    else if (this.data.snaga > 44 && this.data.snaga <= 55)
      this.rezultat.polisa = 15517;
    else if (this.data.snaga > 55 && this.data.snaga <= 66)
      this.rezultat.polisa = 17421;
    else if (this.data.snaga > 66 && this.data.snaga <= 84)
      this.rezultat.polisa = 19976;
    else if (this.data.snaga > 84 && this.data.snaga <= 110)
      this.rezultat.polisa = 23799;
    else if (this.data.snaga > 110)
      this.rezultat.polisa = 28261;

    //broj steta
    if (this.data.brojSteta == 0) //bolji premijski stepen za 1
    {
      this.data.tempPremijskiStepen = this.data.premijskiStepen - 1;
      if (this.data.tempPremijskiStepen < 1)
        this.data.tempPremijskiStepen = 1;
    }
    else {
      this.data.tempPremijskiStepen = this.data.premijskiStepen + 3;
      if (this.data.tempPremijskiStepen > 12)
        this.data.tempPremijskiStepen = 12;
    }
    //premijski
    switch (this.data.tempPremijskiStepen) {
      case 1: {
        this.rezultat.premijski = -Math.round(this.rezultat.polisa * 0.25)
        break;
      }
      case 2: {
        this.rezultat.premijski = -Math.round(this.rezultat.polisa * 0.15)
        break;
      }
      case 3: {
        this.rezultat.premijski = -Math.round(this.rezultat.polisa * 0.05)
        break;
      }
      case 4: {
        this.rezultat.premijski = -Math.round(this.rezultat.polisa * 0.0)
        break;
      }
      case 5: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 0.15)
        break;
      }
      case 6: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 0.30)
        break;
      }
      case 7: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 0.50)
        break;
      }
      case 8: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 0.70)
        break;
      }
      case 9: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 0.90)
        break;
      }
      case 10: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 1.10)
        break;
      }
      case 11: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 1.30)
        break;
      }
      case 12: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 1.50)
        break;
      }
      default: {
        this.rezultat.premijski = 0;
        break;
      }
    }



    let starost = new Date().getFullYear() - this.data.godiste;
    //porez
    if (this.data.zapremnina <= 1150) {
      if (starost <= 5)
        this.rezultat.porez = 1780;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 1513;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 1335;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 1068;
      if (starost >= 20)
        this.rezultat.porez = 356;
    }
    else if (this.data.zapremnina >= 1151 && this.data.zapremnina <= 1300) {
      if (starost <= 5)
        this.rezultat.porez = 3490;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 2966;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 2617;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 2094;
      if (starost >= 20)
        this.rezultat.porez = 698;
    }
    else if (this.data.zapremnina >= 1301 && this.data.zapremnina <= 1600) {
      if (starost <= 5)
        this.rezultat.porez = 7680;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 6528;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 5760;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 4608;
      if (starost >= 20)
        this.rezultat.porez = 1536;
    }
    else if (this.data.zapremnina >= 1601 && this.data.zapremnina <= 2000) {
      if (starost <= 5)
        this.rezultat.porez = 15750;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 13387;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 11812;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 9450;
      if (starost >= 20)
        this.rezultat.porez = 3150;
    }
    else if (this.data.zapremnina >= 2001 && this.data.zapremnina <= 2500) {
      if (starost <= 5)
        this.rezultat.porez = 77850;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 58387;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 46710;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 27247;
      if (starost >= 20)
        this.rezultat.porez = 15570;
    }
    else if (this.data.zapremnina >= 2501 && this.data.zapremnina <= 3000) {
      if (starost <= 5)
        this.rezultat.porez = 157730;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 118297;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 94638;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 55205;
      if (starost >= 20)
        this.rezultat.porez = 31546;
    }
    else if (this.data.zapremnina >= 3001) {
      if (starost <= 5)
        this.rezultat.porez = 326020;
      if (starost >= 6 && starost <= 8)
        this.rezultat.porez = 244515;
      if (starost >= 9 && starost <= 10)
        this.rezultat.porez = 195612;
      if (starost >= 11 && starost <= 19)
        this.rezultat.porez = 114107;
      if (starost >= 20)
        this.rezultat.porez = 65204;
    }


    //dodatne informacije o vozilu
    if (this.data.zeleniKarton)
      this.rezultat.zeleniKarton = 2000;
    else
      this.rezultat.zeleniKarton = 0;

    //saobracajna dozvola
    if (this.data.saobracajna) {
      this.rezultat.ratSaobracajna = 820;
      this.rezultat.ratZahtevSaobracajna = 380;
      this.rezultat.nbsSaobracajna = 660;
    }
    else {
      this.rezultat.ratZahtevSaobracajna = 0;
      this.rezultat.ratSaobracajna = 0;
      this.rezultat.nbsSaobracajna = 0;
    }

    //tablice
    if (this.data.tablice) {
      this.rezultat.nbsTablice = 1093;
      this.rezultat.ratTablice = 1380;
    }
    else {
      this.rezultat.ratTablice = 0;
      this.rezultat.nbsTablice = 0;
    }

    //rent
    if (this.data.rentCar)
      this.rezultat.rentCar = this.rezultat.polisa * -0.4;
    else
      this.rezultat.rentCar = 0;

    //taxi
    if (this.data.taxi)
      this.rezultat.taxi = this.rezultat.polisa * 0.2;
    else
    this.rezultat.taxi = 0;
  
    //invalid
    if (this.data.invalid)
      this.rezultat.invalid = this.rezultat.polisa * -0.1
    else
      this.rezultat.invalid = 0;

    //elektricno vozilo
    if (this.data.elektricnoVozilo == 2) // hybrid
    {
      this.rezultat.porez = 0;
    }
    else if (this.data.elektricnoVozilo == 3) // full struja
    {
      this.rezultat.porez = 0;
      this.rezultat.komunalna = 0;
    }


    //USLUGE

    //tehnicki
    if (this.data.tehnickiPregled) {
      this.rezultat.tehnicki = 6000;
    }
    else {
      this.rezultat.tehnicki = 0;
    }
    //tabliceSaobracana
    if (this.data.pripremaDokumentacije) {
      this.rezultat.pripremaDokumentacije = 1500;
    }
    else {
      this.rezultat.pripremaDokumentacije = 0;
    }
    //izdavanjeNalepnice
    if (this.data.odlazakSup) {
      this.rezultat.odlazakSup = 2000;
    }
    else {
      this.rezultat.odlazakSup = 0;
    }

    //stavke
    this.rezultat.sum = this.rezultat.komunalna + this.rezultat.polisa + this.rezultat.premijski + this.rezultat.porez;
    this.rezultat.sum += this.rezultat.zeleniKarton;
    this.rezultat.sum += (this.rezultat.ratSaobracajna +this.rezultat.ratZahtevSaobracajna+this.rezultat.nbsSaobracajna);
    this.rezultat.sum += (this.rezultat.ratTablice + this.rezultat.nbsTablice);
    this.rezultat.sum += (this.rezultat.invalid + this.rezultat.taxi + this.data.rentCar);
    this.rezultat.sum += (this.rezultat.ratNalepnica + this.rezultat.ratZahtevNalepnica + this.rezultat.nbsNalepnica);
    //usluge
    this.rezultat.sum += (this.rezultat.tehnicki);
    this.rezultat.sum += (this.rezultat.odlazakSup + this.rezultat.pripremaDokumentacije);
    
    this.toggleModal();
  }
}