import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-kalkulator-registracije-selektor',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './kalkulator-registracije-selektor.component.html',
  styleUrl: './kalkulator-registracije-selektor.component.scss'
})
export class KalkulatorRegistracijeSelektorComponent {

  constructor(private router: Router)
  {

  }
  navigate(route: string) {
    this.router.navigate([route]).then(() => {
      window.scrollTo(0, 0);
    });
  }
}
