import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

export interface ResultData {
  komunalna: number; // Municipal fee
  ratZahtevNalepnica: number; // Installment request for the sticker
  ratNalepnica: number; // Installment for the sticker
  ratSaobracajna: number; // Installment for the traffic insurance
  nbsNalepnica: number; // National Bank sticker cost
  nbsSaobracajna: number; // National Bank traffic insurance cost
  ratZahtevSaobracajna: number; // Installment request for traffic insurance
  nbsTablice: number; // National Bank license plate cost
  ratTablice: number; // Installment for license plates
  polisa: number; // Policy cost
  zeleniKarton: number; // Green card cost
  porez: number; // Tax
  elektricnoVozilo: number; // Electric vehicle discount or fee
  premijski: number; // Premium
  tehnicki: number; // Technical inspection cost
  rentCar: number; // Rental car-related cost
  invalid: number; // Disability discount or fee
  pripremaDokumentacije: number; // Sticker issuance cost
  odlazakSup: number; // Cost for plates and traffic registration
  sum: number; // Total sum
}

@Component({
  selector: 'app-kalkulator-registracije-teretno',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './kalkulator-registracije-teretno.component.html',
  styleUrl: './kalkulator-registracije-teretno.component.scss'
})
export class KalkulatorRegistracijeTeretnoComponent implements OnInit {
  data: any = {
    tipVozila: 1,
    elektricnoVozilo: 1,
    godiste: null,
    snaga: null,
    zapremnina: null,
    premijskiStepen: 4,
    tempPremijskiStepen: 4,
    invalid: false,
    saobracajna: false,
    tablice: false,
    zeleniKarton: false,
    brojSteta: 0,
    pripremaDokumentacije: false,
    odlazakSup: false,
    rentCar: false,
    tehnickiPregled: false,
  }; cena: number = 0;
  modal: boolean = false;
  image: boolean = false;
  imagemap: number = 0;
  rezultat: ResultData = {
    komunalna: 0,
    ratZahtevNalepnica: 400,
    ratNalepnica: 250,
    ratSaobracajna: 0,
    nbsNalepnica: 236,
    nbsSaobracajna: 0,
    ratZahtevSaobracajna: 0,
    nbsTablice: 0,
    ratTablice: 0,
    polisa: 0,
    zeleniKarton: 0,
    porez: 0,
    elektricnoVozilo: 0,
    premijski: 0,
    tehnicki: 0,
    rentCar: 0,
    invalid: 0,
    pripremaDokumentacije: 0,
    odlazakSup: 0,
    sum: 0,
  }
  ngOnInit(): void {

  }
  constructor(private toastr: ToastrService) {

  }
  toggleModal() {
    this.modal = !this.modal;
  }
  toggleModalimage(num: number) {
    this.imagemap = num;
    this.image = !this.image;
  }

  calculate(f: any) {
    if (!f) {
      this.toastr.warning("Molimo popunite ispravno formu.")
      return;
    }

    //komunalna taksa
    if (this.data.nosivost! >= 0 && this.data.nosivost! <= 2000)
      this.rezultat.komunalna = 2360;
    else if (this.data.nosivost! >= 2000 && this.data.nosivost! <= 2500)
      this.rezultat.komunalna = 3150;


    //polisa
    if (this.data.nosivost! <= 500)
      this.rezultat.polisa = 19923;
    else if (this.data.nosivost! > 500 && this.data.nosivost! <= 1000)
      this.rezultat.polisa = 22086;
    else if (this.data.nosivost! > 1000 && this.data.nosivost! <= 2000)
      this.rezultat.polisa = 34625;
    else if (this.data.nosivost! > 2000 && this.data.nosivost! <= 3000)
      this.rezultat.polisa = 37929;

    //broj steta
    if (this.data.brojSteta == 0) //bolji premijski stepen za 1
    {
      this.data.tempPremijskiStepen = this.data.premijskiStepen - 1;
      if (this.data.tempPremijskiStepen < 1)
        this.data.tempPremijskiStepen = 1;
    }
    else {
      this.data.tempPremijskiStepen = this.data.premijskiStepen + 3;
      if (this.data.tempPremijskiStepen > 12)
        this.data.tempPremijskiStepen = 12;
    }
    //premijski
    switch (this.data.tempPremijskiStepen) {
      case 1: {
        this.rezultat.premijski = -Math.round(this.rezultat.polisa * 0.25)
        break;
      }
      case 2: {
        this.rezultat.premijski = -Math.round(this.rezultat.polisa * 0.15)
        break;
      }
      case 3: {
        this.rezultat.premijski = -Math.round(this.rezultat.polisa * 0.05)
        break;
      }
      case 4: {
        this.rezultat.premijski = -Math.round(this.rezultat.polisa * 0.0)
        break;
      }
      case 5: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 0.15)
        break;
      }
      case 6: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 0.30)
        break;
      }
      case 7: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 0.50)
        break;
      }
      case 8: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 0.70)
        break;
      }
      case 9: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 0.90)
        break;
      }
      case 10: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 1.10)
        break;
      }
      case 11: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 1.30)
        break;
      }
      case 12: {
        this.rezultat.premijski = Math.round(this.rezultat.polisa * 1.50)
        break;
      }
      default: {
        this.rezultat.premijski = 0;
        break;
      }
    }
    //dodatne informacije o vozilu
    if (this.data.zeleniKarton)
      this.rezultat.zeleniKarton = 2000;
    else
      this.rezultat.zeleniKarton = 0;

    //saobracajna dozvola
    if (this.data.saobracajna) {
      this.rezultat.ratSaobracajna = 820;
      this.rezultat.ratZahtevSaobracajna = 380;
      this.rezultat.nbsSaobracajna = 660;
    }
    else {
      this.rezultat.ratZahtevSaobracajna = 0;
      this.rezultat.ratSaobracajna = 0;
      this.rezultat.nbsSaobracajna = 0;
    }

    //tablice
    if (this.data.tablice) {
      this.rezultat.nbsTablice = 1093;
      this.rezultat.ratTablice = 1380;
    }
    else {
      this.rezultat.ratTablice = 0;
      this.rezultat.nbsTablice = 0;
    }

    //rent
    if (this.data.rentCar)
      this.rezultat.rentCar = this.rezultat.polisa * -0.4;
    else
      this.rezultat.rentCar = 0;

    //invalid
    if (this.data.invalid)
      this.rezultat.invalid = this.rezultat.polisa * -0.1
    else
      this.rezultat.invalid = 0;

    //elektricno vozilo
    if (this.data.elektricnoVozilo == 2) // hybrid
    {
      this.rezultat.porez = 0;
    }
    else if (this.data.elektricnoVozilo == 3) // full struja
    {
      this.rezultat.porez = 0;
      this.rezultat.komunalna = 0;
    }

    //USLUGE

    //tehnicki
    if (this.data.tehnickiPregled) {
      this.rezultat.tehnicki = 6000;
    }
    else {
      this.rezultat.tehnicki = 0;
    }
    //tabliceSaobracana
    if (this.data.pripremaDokumentacije) {
      this.rezultat.pripremaDokumentacije = 1500;
    }
    else {
      this.rezultat.pripremaDokumentacije = 0;
    }
    //izdavanjeNalepnice
    if (this.data.odlazakSup) {
      this.rezultat.odlazakSup = 2000;
    }
    else {
      this.rezultat.odlazakSup = 0;
    }

    //stavke
    this.rezultat.sum = this.rezultat.komunalna + this.rezultat.polisa + this.rezultat.premijski + this.rezultat.porez;
    this.rezultat.sum += this.rezultat.zeleniKarton;
    this.rezultat.sum += (this.rezultat.ratSaobracajna + this.rezultat.ratZahtevSaobracajna + this.rezultat.nbsSaobracajna);
    this.rezultat.sum += (this.rezultat.ratTablice + this.rezultat.nbsTablice);
    this.rezultat.sum += (this.rezultat.invalid + this.data.rentCar);
    this.rezultat.sum += (this.rezultat.ratNalepnica + this.rezultat.ratZahtevNalepnica);
    //usluge
    this.rezultat.sum += (this.rezultat.tehnicki);
    this.rezultat.sum += (this.rezultat.odlazakSup + this.rezultat.pripremaDokumentacije);
    this.toggleModal();
  }

}
