<div class="banner">
    <span class="title">KONTAKT</span>
</div>

<div class="contact-row">
    <div class="contact-element">
        <img src="../../../../../assets/icons/clock.svg"/>
        <span class="title">
            Pon-Pet: <b>07:00 - 21:00</b><br>
            Subota: <b>07:00 - 17:00</b><br>
            Nedelja: <b>neradni dan</b><br>
        </span>
    </div>
    <div class="contact-element">
        <img src="../../../../../assets/icons/info.svg"/>
        <span class="title">
            PIB: <b>114445680</b><br>
            MB: <b>22024876</b><br>
            Delatnost: <b>7120</b><br>
        </span>
    </div>
    <div class="contact-element">
        <img src="../../../../../assets/icons/phone.svg"/>
        <span class="title"><a href="tel:+38166100777">+38166100777</a></span>
    </div>
    <div class="contact-element">
        <img src="../../../../../assets/icons/email.svg"/>
        <span class="title"><a href="mailto:info@cuburac.rs">info&#64;cuburac.rs</a></span>
    </div>
    <div class="contact-element">
        <img src="../../../../../assets/icons/map.svg"/>
        <span class="title"><a href="https://www.google.com/maps/@44.7948495,20.4782596,0a,82.2y,113.28h,90t/data=!3m4!1e1!3m2!1sZG1KYguVjeJ9hz_MDqscdA!2e0?source=apiv3&hl=sr">
            Maksima Gorkog 59<br> Beograd
        </a></span>
    </div>


</div>

<div class="row">
    <div class="map">
        <div class="iframe">
            <!-- <iframe
                src="https://www.google.com/maps/embed?pb=!4v1725199474628!6m8!1m7!1sZG1KYguVjeJ9hz_MDqscdA!2m2!1d44.79484953267398!2d20.47825959182232!3f113.28!4f0!5f0.7820865974627469"
                allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2831.3149370283736!2d20.47587787618562!3d44.79476997107098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a70711d6d42f3%3A0xde10437e6dc3c50f!2z0JzQsNC60YHQuNC80LAg0JPQvtGA0LrQvtCzIDU5LCDQkdC10L7Qs9GA0LDQtCAxMTAwMA!5e0!3m2!1ssr!2srs!4v1725199549022!5m2!1ssr!2srs"  
              allowfullscreen="" 
              loading="lazy" 
              referrerpolicy="no-referrer-when-downgrade">
            </iframe>
        </div>

    </div>
    <div class="kontakt" id="kontakt">
        <app-contact-form style="width: 95%;"></app-contact-form>
    </div>
</div>